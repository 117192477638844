import React from "react";
import "./Main.css";
import Name from "../Name/Name.js";
import Profession from "../Profession/Profession.js";
import Design from "../Design/Design.js";

function Main() {
  return (
    <main className='main'>
      <div className='main__column'>
        <Name />
        <Profession />
        <Design />
      </div>

      <nav className='main__navigation'>
        <ul className='main__navigation_list'>
          <li className='main__navigation_list-item'>
            <a className='main__navigation_link' href='/about'>
              Обо мне
            </a>
          </li>
          <li className='main__navigation_list-item'>
            <a className='main__navigation_link' href='/work'>
              Работы
            </a>
          </li>
          <li className='main__navigation_list-item'>
            <a className='main__navigation_link' href='/contacts'>
              Контакты
            </a>
          </li>
          <li className='main__navigation_list-item'>
            <a className='main__navigation_link' href='/certificates'>
              Сертификаты
            </a>
          </li>
        </ul>
      </nav>
    </main>
  );
}

export default Main;

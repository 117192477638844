import React from "react";
import Name from "../Name/Name";
import Navigation from "../Navigation/Navigation";
import Profession from "../Profession/Profession";
import "./Header.css";
import bg from "../../images/pic0-min.jpg";

function Header({ screenSize }) {
  return (
    <header className='header'>
      {screenSize.width < screenSize.height && (
        <img src={bg} alt='bg' className='header__bg' />
      )}
      <Name />
      <Profession />

      {(screenSize.width <= screenSize.height || screenSize.width < 700) && (
        <Navigation />
      )}
    </header>
  );
}

export default Header;

import React from "react";
import "./Design.css";

function Design() {
  return (
    <a
      href="mailto:alurhome@gmail.com"
      className="design"
      target="_blank"
      rel="noopener noreferrer">
      Дизайн сайта — Алексей Ворожейкин
    </a>
  );
}

export default Design;

import React from "react";
import "./Contacts.css";
import Navigation from "../Navigation/Navigation";
import tel from "../../images/tel.svg";
import mail from "../../images/mail.svg";
import telegram from "../../images/telegram.svg";

function Contacts({ screenSize }) {
  return (
    <div className='contacts'>
      {screenSize.width > screenSize.height && screenSize.width > 700 && (
        <Navigation />
      )}
      <div className='contacts__column'>
        <h2 className='contacts__title'>Контакты</h2>
        <ul className='contacts__list'>
          <li className='contacts__list-item'>
            <a className='contacts__list-item_link' href='tel:+79117279407'>
              <img
                src={tel}
                alt='telephone icon'
                className='contacts__list-item_img'
              />
              +7 911 727 94 07
            </a>
          </li>
          <li className='contacts__list-item'>
            <a
              className='contacts__list-item_link'
              href='mailto:litvinov.maksim.konstantinovich@gmail.com?subject=Предложение о работе'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={mail}
                alt='mail icon'
                className='contacts__list-item_img'
              />
              litvinov.maksim.konstantinovich@gmail.com
            </a>
          </li>
          <li className='contacts__list-item'>
            <a
              className='contacts__list-item_link'
              href='https://t.me/hosfatantabolis'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={telegram}
                alt='telegram icon'
                className='contacts__list-item_img'
              />
              @hosfatantabolis
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contacts;

import React from "react";
import "./Work.css";
import Navigation from "../Navigation/Navigation";
// import mesto from "../../images/sites-screenshots/mesto-min.PNG";
// import howtolearn from "../../images/sites-screenshots/how-to-learn-min.PNG";
// import russiantravel from "../../images/sites-screenshots/russian-travel-min.PNG";
// import randombeer from "../../images/sites-screenshots/random-beer.png";
import scanner from "../../images/sites-screenshots/scanner-logo.png";
// import notes from "../../images/sites-screenshots/notes.png";
// import audio_player from "../../images/sites-screenshots/audio_player.PNG";

function Work({ screenSize }) {
  return (
    <div className='work'>
      {screenSize.width > screenSize.height && screenSize.width > 700 && (
        <Navigation />
      )}
      <div className='work__column'>
        <h2 className='work__title'>Работы</h2>
        <ul className='work__list'>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://movies.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project movies'
                className='work__list-image work__list-image_movies'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Проект "Фильмы"</h3>
                movies.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://mesto.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project mesto'
                className='work__list-image work__list-image_mesto'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Проект "Место"</h3>
                mesto.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://how-to-learn.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project how-to-learn'
                className='work__list-image work__list-image_how'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Проект "Научиться учиться"
                </h3>
                how-to-learn.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://russian-travel.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project russian travel'
                className='work__list-image work__list-image_travel'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Проект "Путешествия по России"
                </h3>
                russian-travel.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://random-beer.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_random'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Проект "Случайное пиво" на Vue.js
                </h3>
                random-beer.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://hosfatantabolis.ru/scanner'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={scanner}
                alt='project scanner'
                className='work__list-image'
              />
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Проект Scanner на React Native
                </h3>
                hosfatantabolis.ru/scanner
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://notes.hosfatantabolis.ru/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project notes'
                className='work__list-image work__list-image_notes'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Проект Заметки на React / Redux
                </h3>
                notes.hosfatantabolis.ru
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://hosfatantabolis.github.io/RS_School_Stage0/js30_1.2-audio-player/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_player'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Audio Player / Аудио Плеер
                </h3>
                Audio Player на Github Pages
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://hosfatantabolis.github.io/RS_School_Stage0/library/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_library'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Library / Библиотека</h3>
                Library на Github Pages
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://hosfatantabolis.github.io/RS_School_Stage0/tic-tac-toe/'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_tic'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Крестики-Нолики</h3>
                Tic-tac-toe на Github Pages
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://rolling-scopes-school.github.io/hosfatantabolis-JSFE2023Q4/coffee-house/index.html'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_coffee'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Coffee House</h3>
                Coffee House на Github Pages
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://rolling-scopes-school.github.io/hosfatantabolis-JSFE2023Q4/hangman/index.html'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_hangman'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>Hangman / Виселица</h3>
                Hangman / Виселица на Github Pages
              </div>
            </a>
          </li>
          <li className='work__list-item'>
            <a
              className='work__list-item_link'
              href='https://rolling-scopes-school.github.io/hosfatantabolis-JSFE2023Q4/nonograms/index.html'
              target='_blank'
              rel='noreferrer'
            >
              <div
                alt='project random beer'
                className='work__list-image work__list-image_nonogram'
              ></div>
              <div className='work__list-item_column'>
                <h3 className='work__list-item_title'>
                  Nonograms / Японский кроссворд
                </h3>
                Nonograms на Github Pages
              </div>
            </a>
          </li>
        </ul>
        <p className='work__text'>
          Полное портфолио:{" "}
          <a
            className='work__link'
            href='https://github.com/hosfatantabolis'
            target='_blank'
            rel='noreferrer'
          >
            https://github.com/hosfatantabolis
          </a>
        </p>
      </div>
    </div>
  );
}

export default Work;

import React from "react";
import Navigation from "../Navigation/Navigation";
import "./Certificates.css";

function Certificates({ screenSize }) {
  return (
    <div className='certificates'>
      {screenSize.width > screenSize.height && screenSize.width > 700 && (
        <Navigation />
      )}
      <div className='certificates__column'>
        <h2 className='certificates__title'>Сертификаты</h2>
        <ul className='certificates__list'>
          <li className='certificates__list-item'>
            <a
              className='certificates__list-item_link'
              href='https://disk.yandex.ru/i/6N7Cnm1ngb5Gww'
              target='_blank'
              rel='noreferrer'
            >
              <div className='certificates__list-item_image certificates__list-item_image-yandex'></div>
              <p className='certificates__list-item_title'>Яндекс.Практикум</p>
            </a>
          </li>
          <li className='certificates__list-item'>
            <a
              className='certificates__list-item_link'
              href='https://app.rs.school/certificate/yp8ssjjj'
              target='_blank'
              rel='noreferrer'
            >
              <div className='certificates__list-item_image certificates__list-item_image-rs0'></div>
              <p className='certificates__list-item_title'>
                Rolling Scopes School Stage 0
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Certificates;

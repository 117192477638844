import React from "react";
import "./Profession.css";
import { useLocation } from "react-router-dom";

function Profession() {
  const location = useLocation();
  return (
    <p
      className={`profession ${
        location.pathname === "/" ? "profession_main-page" : ""
      }`}>
      Front-end разработчик
    </p>
  );
}

export default Profession;

import React from "react";
import "./About.css";
import Navigation from "../Navigation/Navigation";

function About({ screenSize }) {
  return (
    <div className='about'>
      {screenSize.width > screenSize.height && screenSize.width > 700 && (
        <Navigation />
      )}
      <div className='about__column'>
        <h2 className='about__title'>Обо мне</h2>
        <p className='about__text'>
          Хочу заниматься веб-разработкой, потому что это мне всегда нравилось.
          Ещё в школе делал для себя сайт по какому-то самоучителю. Недавно я
          успешно окончил курсы Яндекс.Практикума и хочу дальше развиваться в
          этой области. На моем настоящем месте работы (не по профилю) я изучил
          почти всё, что возможно и де-факто стал заместителем начальника
          склада. Сейчас работа стала однообразной, а в веб-разработке не
          заскучаешь. К тому же, можно сразу видеть результат своих трудов. В
          постоянно развивающейся индустрии каждый проект привносит новые знания
          и позволяет постоянно развиваться. А также остается место для
          творчества, которое тоже важно для меня. Хочу принимать участие в
          создании полезного продукта.
        </p>
        <div className='about__text'>
          Из хобби:
          <ul className='about__list'>
            <li className='about__list-item'>
              По части разработки: периодически слушаю подкаст "Сделайте мне
              красиво", смотрю на YouTube тематические видео (Ben Awad, Ulbi TV
              индусы и пр.), почитываю в Telegram каналы с тестами и заданиями
              по HTML, JS, CSS (JavaScript Tasks, Frontender Libs, HTML/CSS
              Tasks). Также интересуюсь тематикой Arduino. Мечтаю купить
              земельный участок и сделать там автоматический парник с миллионом
              огурцов.
            </li>
            <li className='about__list-item'>
              Фотографирую летящий в аквариум сломанный фотоаппарат. Собираю из
              кусков светодиодной ленты светящуюся палку на аккумуляторах и иду
              фоткать на длинной выдержке. Фоткал Ленина, лыжи, и светящееся
              дерево в парке, а вот с Луной пока не выходит. Иногда щёлкаю
              друзей. К незнакомцам не пристаю.
            </li>
            <li className='about__list-item'>
              Слушаю музыку, много. В основном - зарубежную и с какой-нибудь
              смысловой нагрузкой. Собираю виниловые пластинки.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default About;

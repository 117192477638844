import React from "react";
import "./Scanner.css";
import Navigation from "../Navigation/Navigation";
import choose from "../../images/sites-screenshots/choose.png";

function Scanner({ screenSize }) {
  return (
    <div className='scanner'>
      {screenSize.width > screenSize.height && screenSize.width > 700 && (
        <Navigation />
      )}
      <div className='scanner__column'>
        <h2 className='scanner__title'>Scanner (React Native)</h2>
        <p className='scanner__text'>
          Приложение на React Native для работы. Есть два типа работников -
          сборка и склад.
        </p>
        <img className='scanner_img' src={choose} alt='choose' />
        <p className='scanner__text'>
          Раньше работа для "сборки" была организована следующим образом:
          <ul className='scanner__list'>
            <li className='scanner__list-item'>
              При готовности заказа работник сборки отправлял номер заказа в
              общий чат в Skype, где состояли все менеджеры.
            </li>
            <li className='scanner__list-item'>
              Менеджеры среди кучи номеров искали свой заказ и совершали нужные
              им действия.
            </li>
            <li className='scanner__list-item'>
              В случае если менеджеру нужно было узнать готов ли его заказ, он
              мог спросить у работников сборки или склада. На выяснение данных
              вопросов уходило время.
            </li>
          </ul>
        </p>
        <p className='scanner__text'>
          А работа для "склада" была организована так:
          <ul className='scanner__list'>
            <li className='scanner__list-item'>
              При поступлении заказа работник склада выполнял поиск заказа в
              базе 1С.
            </li>
            <li className='scanner__list-item'>Принимал заказ.</li>
            <li className='scanner__list-item'>
              Писал менеджеру личное сообщение в Skype о принятии заказа.
            </li>
          </ul>
        </p>
        <p className='scanner__text'>
          Теперь с помощью приложения облегчается жизнь следующим образом:
          <ul className='scanner__list'>
            <li className='scanner__list-item'>
              При готовности заказа, "сборка" сканирует специальный QR-код
              заказа. Лично определенному менеджеру отправляется сообщение в
              Telegram от бота. Менеджеру не нужно искать свой заказ среди
              других в общем чате. А также не нужно спрашивать ни у кого, готов
              ли его заказ или нет.
            </li>
            <li className='scanner__list-item'>
              При принятии заказа, "склад" сканирует специальный QR-код заказа.
              Лично определенному менеджеру отправляется сообщение в Telegram от
              бота. Работнику склада не нужно ничего никому писать.
            </li>
            <li className='scanner__list-item'>
              Функционал для "склада" был впоследствии расширен и интегрирован в
              1С. Функция отправки сообщений реализована мной.
            </li>
          </ul>
        </p>
        <iframe
          title='scanner'
          src='https://youtube.com/embed/TwWk4HRIU3k?feature=share'
          style={{ border: "0px #ffffff none" }}
          name='scanner'
          scrolling='no'
          frameBorder='1'
          marginHeight='0px'
          marginWidth='0px'
          height='auto'
          width='100%'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Scanner;

import React from "react";
import "./Navigation.css";
import { useLocation } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  return (
    <nav className='navigation'>
      <ul className='navigation_list'>
        <li className='navigation_list-item'>
          <a className='navigation_link' href='/'>
            ←
          </a>
        </li>

        {(location.pathname === "/contacts".toLowerCase() ||
          location.pathname === "/work".toLowerCase() ||
          location.pathname === "/scanner".toLowerCase() ||
          location.pathname === "/certificates".toLowerCase()) && (
          <li className='navigation_list-item'>
            <a className='navigation_link' href='/about'>
              Обо мне
            </a>
          </li>
        )}
        {(location.pathname === "/about".toLowerCase() ||
          location.pathname === "/contacts".toLowerCase() ||
          location.pathname === "/scanner".toLowerCase() ||
          location.pathname === "/certificates".toLowerCase()) && (
          <li className='navigation_list-item'>
            <a className='navigation_link' href='/work'>
              Работы
            </a>
          </li>
        )}
        {(location.pathname === "/about".toLowerCase() ||
          location.pathname === "/work".toLowerCase() ||
          location.pathname === "/scanner".toLowerCase() ||
          location.pathname === "/certificates".toLowerCase()) && (
          <li className='navigation_list-item'>
            <a className='navigation_link' href='/contacts'>
              Контакты
            </a>
          </li>
        )}
        {(location.pathname === "/about".toLowerCase() ||
          location.pathname === "/contacts".toLowerCase() ||
          location.pathname === "/work".toLowerCase() ||
          location.pathname === "/scanner".toLowerCase()) && (
          <li className='navigation_list-item'>
            <a className='navigation_link' href='/certificates'>
              Сертификаты
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;

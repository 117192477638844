import React from "react";

import { Route, useHistory, Switch, BrowserRouter } from "react-router-dom";

import "./App.css";
import Header from "../Header/Header.js";
import Main from "../Main/Main.js";
import Work from "../Work/Work.js";
import About from "../About/About.js";
import Contacts from "../Contacts/Contacts.js";
import NotFound from "../NotFound/NotFound";
import Scanner from "../Scanner/Scanner";
import Certificates from "../Certificates/Certificates.js";

function App() {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width, windowSize.height]);

  const history = useHistory();

  return (
    <div className='App'>
      <BrowserRouter history={history}>
        <Switch>
          <Route exact path='/'>
            <Main />
          </Route>
          <Route exact path='/about'>
            <Header screenSize={windowSize} />
            <main className='app__wrapper'>
              <About screenSize={windowSize} />
            </main>
          </Route>
          <Route exact path='/work'>
            <Header screenSize={windowSize} />
            <main className='app__wrapper'>
              <Work screenSize={windowSize} />
            </main>
          </Route>
          <Route exact path='/contacts'>
            <Header screenSize={windowSize} />
            <main className='app__wrapper'>
              <Contacts screenSize={windowSize} />
            </main>
          </Route>
          <Route exact path='/scanner'>
            <Header screenSize={windowSize} />
            <main className='app__wrapper'>
              <Scanner screenSize={windowSize} />
            </main>
          </Route>
          <Route exact path='/certificates'>
            <Header screenSize={windowSize} />
            <main className='app__wrapper'>
              <Certificates screenSize={windowSize} />
            </main>
          </Route>
          <Route exact path='/*'>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import "./Name.css";
import { useLocation } from "react-router-dom";

function Name() {
  const location = useLocation();
  return (
    <h1 className={`name ${location.pathname === "/" ? "name_main-page" : ""}`}>
      Максим Литвинов
    </h1>
  );
}

export default Name;
